import { LOCATION_SEARCH_ENDPOINT,MAM_LOCATIONS_ENDPOINT } from '../../../shared/constants/api-helpers.js'
import fetchData from '../../../shared/services/fetchData'
import { googleHelper } from '../../../shared/utility-functions/google-map-helper.js'
import './fad-map.scss'
import DirectionsLink from '../../../shared/components/DirectionsLink/index';

const fetchLocationData = async (flowType) => {
    let params = { endPoint: LOCATION_SEARCH_ENDPOINT }
    let locationsResults

    try {
        locationsResults = await fetchData(params)
        return locationsResults
    }
    catch (error) {
        console.error('Error fetching data:', error)
    }
}

export const getLocationsForSelectedProvider = async (selectedProvider, searchResults) => {
    let validLocations = await createValidLocationSet(searchResults, true)
    let providerAddressKeys = []
    let locationsForSelectedProvider = []
    
    for (let address of selectedProvider.allAddresses) { 
        providerAddressKeys.push(address.id)
    }

    for (let location of validLocations.validOPGLocations) {
        let addressKeys = location.AddressKey.split(/,\s*/)
        for (let key of addressKeys) {
            if (providerAddressKeys.includes(key.trim())) {
                locationsForSelectedProvider.push(location)
                break
            }
        }
    }

    for (let location of validLocations.validIndependentLocations) {
        if (providerAddressKeys.includes(location.id)) {
            locationsForSelectedProvider.push(location)
        }
    }

    return locationsForSelectedProvider
}

export const createValidLocationSet = async (searchResults, flowType, allAddresses = null) => {
    let addressKeyCount = {}
    let uniqueAddressKeyList = []
    let duplicateAddressKeyListError = []
    let searchResultsAddressesList = []
    let validOPGLocations = []
    if (flowType !== 'mam') {
        var locationsList = await fetchLocationData(flowType)
        const locationsListFiltered = locationsList.results.filter(item => item.AddressKey !== null)

        for (let location of locationsListFiltered) {
            addressKeyCount[location.AddressKey] = (addressKeyCount[location.AddressKey] || 0) + 1
        }
        for (let location of locationsListFiltered) {
            if (addressKeyCount[location.AddressKey] === 1) {
                uniqueAddressKeyList.push(location)
            } else {
                duplicateAddressKeyListError.push(location)
            }
        }
        // try {
        //     if (duplicateAddressKeyListError.length !== 0) {
        //         throw new Error('Duplicate Address Keys: location data contains duplicate address keys ')
        //     }
        // }
        // catch (error) {
        //     console.error(error)
        // }
    }
    if (allAddresses === null) {
        for (let provider of searchResults) {
            searchResultsAddressesList.push(...provider.addresses)
        }
    } else {
        for (let provider of searchResults) {
            searchResultsAddressesList.push(...provider.allAddresses)
        }
    }

    let searchResultsAddressesSet = new Set(searchResultsAddressesList.map(address => address.id))
    let searchResultsAddressesIDsthatMatch = []


    const distanceAddressMap = new Map(searchResultsAddressesList.map(address => [address.id, address.d]))

    
    for (let location of uniqueAddressKeyList) {
        let addressKeys = location.AddressKey.split(/,\s*/)// to handle locations having multiple address keys within a string 

        for (let key of addressKeys) {
            if (searchResultsAddressesSet.has(key.trim())) {
                location.customDistance = distanceAddressMap.get(key.trim()) // add custom distance to locations gathered from locations endpoint
                searchResultsAddressesIDsthatMatch.push(addressKeys)
                validOPGLocations.push(location)
                break
            }
        }
    }

    
    const transformedSearchResultsAddressesIDsthatMatch = []
    for (let idList of searchResultsAddressesIDsthatMatch) {
        for (let id of idList) {
            const splitItems = id.split(/,\s*/)
            splitItems.forEach(splitItem => transformedSearchResultsAddressesIDsthatMatch.push(splitItem))
        }       
    }


    
    let searchResultsAddressesIDsthatMatchSet = new Set(transformedSearchResultsAddressesIDsthatMatch)

    let searchResultsAddressesIDsthatDoNotMatchSet = new Set(searchResultsAddressesSet)
    for (let elem of searchResultsAddressesIDsthatMatchSet) {
        searchResultsAddressesIDsthatDoNotMatchSet.delete(elem)
    }


    let remainingLocationsToAddToValidLocations = []
    for (let address of searchResultsAddressesList) {
        if (searchResultsAddressesIDsthatDoNotMatchSet.has(address.id)) {
            remainingLocationsToAddToValidLocations.push(address)
        }
    }

    let uniqueRemainingLocationsToAddToValidLocationsMap = new Map(remainingLocationsToAddToValidLocations.map(address => [address.id, address]))
    let validIndependentLocations = Array.from(uniqueRemainingLocationsToAddToValidLocationsMap.values())

    if (flowType === 'mam') {
        return { validOPGLocations: searchResults, validIndependentLocations: validIndependentLocations };
    }

    return { validOPGLocations: validOPGLocations, validIndependentLocations: validIndependentLocations }
}

export const createLocationIcon = (locationUrl, position, map, locationDetails, noOfLocations = 0) => {
    let imageUrl = `${locationUrl}`
    class DefaultLocationImageOverlay extends google.maps.OverlayView {
        constructor(position, imageUrl, hoveredOverlay, locationDetails) {
            super();
            this.position = position;
            this.imageUrl = imageUrl;
            this.div = null;
            this.image = null;
            this.hoveredOverlay = hoveredOverlay;
            this.locationDetails = locationDetails; // Store location details
            this.closeTimeoutId = null;
        }

        onAdd() {
            this.div = document.createElement("div");
            this.image = document.createElement("img");
            this.div.appendChild(this.image);

            this.div.className = "location-map-icon";
            this.image.className = "location-map-icon-img";
            this.image.src = this.imageUrl;

            const panes = this.getPanes();
            panes.overlayMouseTarget.appendChild(this.div); // Attach to mouse target pane for interaction

            const addressBuilder = () => ({
                street: this.locationDetails.AddressLine1 ? this.locationDetails.AddressLine1 : this.locationDetails.adr,
                cityStateZip: `${this.locationDetails.City ? this.locationDetails.City : this.locationDetails.c}, ${this.locationDetails.State ? this.locationDetails.State : this.locationDetails.s} ${this.locationDetails.ZipCode ? this.locationDetails.ZipCode : this.locationDetails.z}`
            });

            const locationId = this.locationDetails.Id !== undefined ? this.locationDetails.Id : this.locationDetails.id;

            const createTooltipContent = () => {
                const address = `${this.locationDetails.AddressLine1 ? this.locationDetails.AddressLine1 : this.locationDetails.adr} ${this.locationDetails.AddressLine2 ? this.locationDetails.AddressLine2 : ''}, ${this.locationDetails.City ? this.locationDetails.City : this.locationDetails.c }`;
                return (
                    <div id={`location-${locationId}`} className='profile-tool-tip-container'>
                        <div className='profile-tool-tip-img-wrapper'>
                            <img className='profile-tool-tip-img' src={this.imageUrl} 
                            alt={this.locationDetails.Name !== undefined ? this.locationDetails.Name : this.locationDetails.c}
                            aria-label={this.locationDetails.Name !== undefined ? this.locationDetails.Name : this.locationDetails.c} />
                        </div>
                        <div className="profile-tool-tip-info">
                            <div className="profile-tool-tip-name">{this.locationDetails.SearchNameOverride !== undefined ? this.locationDetails.SearchNameOverride : (this.locationDetails.Name ? this.locationDetails.Name : this.locationDetails.lbl)}</div>
                            <div className="profile-tool-tip-address-line-2">{ address }</div>
                            <div className="profile-tool-tip-phone">{this.locationDetails.Phone !== undefined ? this.locationDetails.Phone : this.locationDetails.cr[0].Number}</div>
                            { (this.locationDetails.AddressLine1 !== undefined || this.locationDetails.adr !== undefined) && (
                                <div className="tool-tip-directions">
                                    <DirectionsLink address={addressBuilder()} text='Directions' displayIcon={false} />
                                </div>
                            )}
                        </div>
                    </div>
                );
            };

            // Create the tooltip content
            const tooltipContent = createTooltipContent();

            const infoWindowMaxWidth = 260;
            const infoWindowDefaultHeight = 260;

            // Create the InfoWindow
            this.infoWindow = new window.google.maps.InfoWindow({
                maxWidth: infoWindowMaxWidth,
                content: '',
                disableAutoPan: true,
            });

            const getScreenPointFromLatLng = (latLng, map) => map.getProjection().fromLatLngToPoint(latLng);

            const getInfoWindowPixelOffset = (position, map) => {
                const center = getScreenPointFromLatLng(map.getCenter(), map)
                const point = getScreenPointFromLatLng(position, map)
                const quadrant = `${point.y > center.y ? "b" : "t"}${point.x < center.x ? "l" : "r"}`
                const infoWindowElement = document.getElementsByClassName('tool-tip-container');
                infoWindowElement.height = infoWindowDefaultHeight;
                let x = infoWindowMaxWidth
                let y = infoWindowDefaultHeight
        
                if (infoWindowElement && infoWindowElement.length > 0) {
                    y = infoWindowElement[0].offsetHeight;
                    x = infoWindowElement[0].offsetWidth;
                }
        
                x /= 1.7;
        
                switch (quadrant) {
                    case "tr":
                        return new google.maps.Size((x * -1), (y * 0.9));
                        break;
                    case "tl":
                        return new google.maps.Size(x, (y * 0.9));
                        break;
                    case "br":
                        return new google.maps.Size((x * -1), (y * 0.1));
                        break;
                    case "bl":
                        return new google.maps.Size(x, (y * 0.1));
                        break;
                    default:
                        return new google.maps.Size(0, 0);
                }
            }
            
            // Show tooltip on hover
            google.maps.event.addDomListener(this.div, "mouseenter", () => {
                const contentElement = document.createElement('div')
                ReactDOM.render(tooltipContent, contentElement)

                this.infoWindow.setContent(contentElement);
                this.infoWindow.setOptions({ pixelOffset: getInfoWindowPixelOffset(this.position, map) });
                this.infoWindow.open({
                    anchor: new google.maps.Marker({ position: this.position, map: map, visible: false }),
                    map,
                    shouldFocus: false,
                });
                this.hoveredOverlay.setMap(map); // Show hovered marker
                this.setMap(null); // Hide default marker
                if (this.closeTimeoutId) {
                    clearTimeout(this.closeTimeoutId);
                    this.closeTimeoutId = null;
                }
            });

            // Hide tooltip on mouse out
            google.maps.event.addDomListener(this.div, "mouseleave", () => {
                this.closeTimeoutId = setTimeout(() => {
                    this.infoWindow.close();
                    this.hoveredOverlay.setMap(null); // Hide hovered marker
                    this.setMap(map); // Show default marker again
                }, 300);
            });

            google.maps.event.addListener(this.infoWindow, 'domready', () => { 
                const el = document.querySelector('.gm-style-iw-chr');
                if (el) el.remove(); // removing the white space from tooltip
                const infoWindowContent = document.getElementById(`location-${locationId}`);
                if (infoWindowContent) {
                    infoWindowContent.addEventListener('wheel', (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }, {passive: false});

                    infoWindowContent.addEventListener('mouseenter', () => {
                        if (this.closeTimeoutId) {
                            clearTimeout(this.closeTimeoutId);
                            this.closeTimeoutId = null;
                        }
                    });

                    infoWindowContent.addEventListener('mouseleave', () => {
                        this.closeTimeoutId = setTimeout(() => {
                            this.infoWindow.close();
                            this.hoveredOverlay.setMap(null); // Hide hovered marker
                            this.setMap(map); // Show default marker again
                        }, 300);
                    });
                }
            });
        }

        draw() {
            const overlayProjection = this.getProjection();
            let point = overlayProjection.fromLatLngToDivPixel(this.position);
            if (this.div) {
                this.div.style.position = "absolute";
                this.div.style.left = window.innerWidth < 1200 ? `${point.x - 21}px` : `${point.x - 32}px`;
                this.div.style.top = window.innerWidth < 1200 ? `${point.y - 21}px` : `${point.y - 32}px`;
            }
        }

        onRemove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }                                       
    }

    class HoveredLocationImageOverlay extends google.maps.OverlayView {
        constructor(position, imageUrl, defaultOverlay) {
            super();
            this.position = position;
            this.imageUrl = imageUrl;
            this.defaultOverlay = defaultOverlay;
            this.div = null;
            this.image = null;
        }

        onAdd() {
            this.div = document.createElement("div");
            this.image = document.createElement("img");
            this.div.appendChild(this.image);

            const panes = this.getPanes(); 
            panes.overlayMouseTarget.appendChild(this.div); 
        }

        draw() {
            const overlayProjection = this.getProjection();
            let point = overlayProjection.fromLatLngToDivPixel(this.position)
            if (this.div) {
                this.div.style.position = "absolute";
                this.div.style.left = `${point.x - 50}px`;
                this.div.style.top = `${point.y - 50}px`;
                this.div.classList.add("location-map-icon-hovered");
                this.div.style.cursor = "pointer"; // Ensure hover interaction
                this.div.style.pointerEvents = "auto"; // Allow interaction
                this.image.classList.add("location-map-icon-hovered-img");
                this.image.src = this.imageUrl;
                // Attach event listener to revert back to default marker on mouse out
                google.maps.event.addDomListener(this.div, "mouseleave", () => {
                    this.defaultOverlay.closeTimeoutId = setTimeout(() => {
                        this.defaultOverlay.infoWindow.close();
                        this.setMap(null); // Hide hovered marker
                        this.defaultOverlay.setMap(map); // Show default marker again
                    }, 300);
                });
            }
        }

        onRemove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }
    }

    // Create both overlays    
    let hoveredOverlay = new HoveredLocationImageOverlay (position, imageUrl, null);
    let defaultOverlay = new DefaultLocationImageOverlay(position, imageUrl, hoveredOverlay, locationDetails);
    
    hoveredOverlay.defaultOverlay = defaultOverlay; // Link back default overlay
    defaultOverlay.setMap(map); // Initially show the default marker
    return { defaultOverlay, hoveredOverlay: hoveredOverlay };
};

export const defaultMarkerIconPNG = window.innerWidth > 770 ? {
    url: '/ClientResources/Website/images/fadmap/map-pin-default.png',
    anchor: window.google && window.google.maps && new google.maps.Point(25, 50),
    scaledSize:  window.google && window.google.maps && new google.maps.Size(50, 50),
    size:  window.google && window.google.maps && new google.maps.Size(50, 50)
} : {
    url: '/ClientResources/Website/images/fadmap/map-pin-default.png',
    anchor:  window.google && window.google.maps && new google.maps.Point(20, 40),
    scaledSize:  window.google && window.google.maps && new google.maps.Size(40, 40)
}

export const hoveredMarkerIconPNG = window.innerWidth > 770 ? {
    url: '/ClientResources/Website/images/fadmap/map-pin-hover.png',
    anchor:  window.google && window.google.maps && new google.maps.Point(32, 64),
    scaledSize:  window.google && window.google.maps && new google.maps.Size(64, 64),
    size:  window.google && window.google.maps && new google.maps.Size(64, 64)
} : {
    url: '/ClientResources/Website/images/fadmap/map-pin-hover.png',
    anchor:  window.google && window.google.maps && new google.maps.Point(27, 54),
    scaledSize:  window.google && window.google.maps && new google.maps.Size(54, 54)
}

export const selectedLightMarkerIconPNG = {
    url: '/ClientResources/Website/images/fadmap/map-pin-default.png',
    anchor:  window.google && window.google.maps && new google.maps.Point(27, 54),
    scaledSize:  window.google && window.google.maps && new google.maps.Size(54, 54)
}

export const xSmallClusterPNG = window.innerWidth >= 1200 ? {
    url: '/ClientResources/Website/images/fadmap/cluster-base-xsmall.png',
    scaledSize:  window.google && window.google.maps && new google.maps.Size(40, 40),
} : {
    url: '/ClientResources/Website/images/fadmap/cluster-base-xsmall.png',
    scaledSize:  window.google && window.google.maps && new google.maps.Size(39, 39),
}

export const smallClusterPNG = window.innerWidth >= 1200 ? {
    url: '/ClientResources/Website/images/fadmap/cluster-base-small.png',
    scaledSize:  window.google && window.google.maps && new google.maps.Size(56, 56),
} : {
    url: '/ClientResources/Website/images/fadmap/cluster-base-small.png',
    scaledSize:  window.google && window.google.maps && new google.maps.Size(47, 47),
}

export const baseClusterPNG = window.innerWidth >= 1200 ? {
    url: '/ClientResources/Website/images/fadmap/cluster-base.png',
    scaledSize: window.google && window.google.maps && new google.maps.Size(80, 80),
} : {
    url: '/ClientResources/Website/images/fadmap/cluster-base.png',
    scaledSize: window.google && window.google.maps && new google.maps.Size(55, 55),
}

export const xSmallActiveClusterPNG = {
    url: '/ClientResources/Website/images/fadmap/cluster-active.png',
    scaledSize: window.google && window.google.maps && new google.maps.Size(50, 50),
    size: window.google && window.google.maps && new google.maps.Size(50, 50)
}

export const smallActiveClusterPNG = {
    url: '/ClientResources/Website/images/fadmap/cluster-active.png',
    scaledSize: window.google && window.google.maps && new google.maps.Size(70, 70),
    size: window.google && window.google.maps && new google.maps.Size(70, 70)
}

 export const baseActiveClusterPNG = {
     url: '/ClientResources/Website/images/fadmap/cluster-active.png',
     scaledSize: window.google && window.google.maps && new google.maps.Size(100, 100),
     size: window.google && window.google.maps && new google.maps.Size(100, 100)
 }
 