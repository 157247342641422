import { race, put, take, delay } from 'redux-saga/effects'
import { fetchAPIData } from '../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { MAM_LOCATIONS_ENDPOINT,MAMMO_VISIT_REGULAR } from '../../shared/constants/api-helpers.js'

let isSagaRunning = false
export default function* mamSearchSaga(waitTime = LONG_TIMEOUT) {
  if (isSagaRunning) return
  isSagaRunning = true

  while (true) {
    let action = yield take('GET_MAM_LOCATIONS')
    let params = action.params ? Object.assign({}, action.params) : null
    params.endPoint = MAM_LOCATIONS_ENDPOINT

    yield put({ type: 'SEARCH_LOCATIONS' })// sets a boolean for data load to true
    yield put({ type: 'CLEAR_LOCATIONS' })// clears providers and sets dataLoaded to false
    // yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'A TO Z' })// sets sort order
    yield put({ type: 'SET_LOCATIONS_CALL_FAILED', value: false })
    if (!params.VisitTypeId) { 
      params.VisitTypeId = window.sessionStorage.getItem('VisitTypeID')|| MAMMO_VISIT_REGULAR
    }

    //Payload value for dist and searchRadius cannot be less than one, otherwise call fails
    const radius = params.dist
    if (Number.isFinite(radius) && radius < 1){
      params.dist = 1
      params.searchRadius = 1
    }

    const { posts } = yield race({
      posts: fetchAPIData(params),
      timeout: delay(waitTime)
    })

    if (posts && posts.Success) {
      let cleanLocations = (posts.MammoLocations && Number.isFinite(radius) && radius < 1) ? posts.MammoLocations.filter(loc => loc.Distance <= radius) : posts.MammoLocations
      let sortedLocations = sortLocations(cleanLocations)

      console.log("=====")
      console.log("Search Radius: ", radius)
      console.log("Response: ", posts.MammoLocations)
      console.log("Cleaned: ", sortedLocations)
      console.log("=====")


      window.sessionStorage.setItem('visitDuration', posts.Duration) 
      window.sessionStorage.setItem('locationCount', posts.MammoLocations.length)     
      window.dispatchEvent(new Event("sessionUpdated"))  
      yield put({ type: 'SET_LOCATIONS', value: sortedLocations })
      yield put({ type: 'SET_MAM_RADIUS', value: radius })
      if (params.lat && params.lng && params.dist && params.sortBy === 'Distance') {
        yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'Distance' })
      }
      else {
        yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'A TO Z' })
      }
    } else {
      yield put({ type: 'SET_LOCATIONS_CALL_FAILED', value: true })

      //When call fails, default to empty locations list
      window.sessionStorage.setItem('visitDuration', 15) 
      window.sessionStorage.setItem('locationCount', 0)     
      window.dispatchEvent(new Event("sessionUpdated"))  
      yield put({ type: 'SET_LOCATIONS', value: [] })
      if (params.lat && params.lng && params.dist && params.sortBy === 'Distance') {
        yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'Distance' })
      }
      else {
        yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'A TO Z' })
      }
    }
  }
}

const sortLocations = (locations) => (
  locations.sort((a, b) => {
    return a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1
  })
)
