import React from 'react'
import appLocation from '../../../shared/constants/applicationLocation.js'
import { waitForLoad } from '../../../shared/utility-functions/IE-window-load.js'
import './map.scss'
let map
export default class Map extends React.Component {
  constructor (props) {
    super(props)
    this.centerLatlng = new google.maps.LatLng(appLocation.lat, appLocation.lng)
    this.state = {
      markers: [],
      results: this.props.results
    }
    this.clearMarkers = this.clearMarkers.bind(this)
    this.refreshMarkers = this.refreshMarkers.bind(this)
    this.initMap = this.initMap.bind(this)

    if (this.props.results && this.props.results.length) {
      waitForLoad('.map-container', this.initMap, 5)
    }
  }

  initMap () {
    let mapContainer = document.querySelector('.map-container')
    if (mapContainer) {
      map = new google.maps.Map(mapContainer, {
        zoom: 14,
        center: this.centerLatlng,
        scrollwheel: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControlStyle: 'small',
        cameraControl: false,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              { visibility: 'off' }
            ]
          }
        ],
        fullscreenControl: false
      })

      this.refreshMarkers(map)
    }
  }

  clearMarkers () {
    this.state.markers.forEach(mark => {
      mark.setMap(null)
    })
    this.state.markers.length = 0

    if (this.markerCluster && typeof this.markerCluster.clearMarkers === 'function') {
      this.markerCluster.clearMarkers()
    }
  }

  handleClick (location) {
    this.props.selectionHandler(location)
  }

  refreshMarkers () {
    let bounds, markerLatLng
    let minClusterZoom = 14
    let centerLatlng = new google.maps.LatLng(appLocation.lat, appLocation.lng)

    this.clearMarkers()
    // this.oms = new OverlappingMarkerSpiderfier(map, { keepSpiderfied: true }) // eslint-disable-line
    bounds = new google.maps.LatLngBounds()

    this.state.results.forEach(location => {
      markerLatLng = new google.maps.LatLng(Number(location.Latitude), Number(location.Longitude))
      this.createMarker(markerLatLng, location)
      bounds.extend(markerLatLng)
    })

    map.setCenter(centerLatlng)
    map.setZoom(11)

    map.fitBounds(bounds)
    map.panToBounds(bounds)

    this.markerCluster = new MarkerClusterer(map, this.state.markers, { // eslint-disable-line
      gridSize: 40,
      maxZoom: 15,
      styles: [
        {
          width: 32,
          height: 44,
          url: '/ClientResources/Website/images/clusterer/p1.png',
          textColor: '#ffffff',
          textSize: 17,
          anchor: [-10, 0]
        },
        {
          width: 42,
          height: 58,
          url: '/ClientResources/Website/images/clusterer/p2.png',
          textColor: '#ffffff',
          textSize: 17,
          anchor: [-15, 0]
        },
        {
          width: 53,
          height: 73,
          url: '/ClientResources/Website/images/clusterer/p3.png',
          textColor: '#ffffff',
          textSize: 17,
          anchor: [-20, 0]
        },
        {
          width: 63,
          height: 88,
          url: '/ClientResources/Website/images/clusterer/p4.png',
          textColor: '#ffffff',
          textSize: 17,
          anchor: [-25, 0]
        }
      ]
    })

    this.markerCluster.setMaxZoom(minClusterZoom)

    window.google.maps.event.addListener(this.markerCluster, 'clusterclick', () => {
      setTimeout(() => {
        if (map.getZoom() > minClusterZoom + 1) {
          map.setZoom(minClusterZoom + 1)
        }
      }, 100)
    })
  }

  createMarker (markerLatLng, location) {
    location.marker = new google.maps.Marker({
      position: markerLatLng,
      map: map,
      id: location.Id,
      title: location.SearchName || location.Name,
      icon: '/ClientResources/Website/images/clusterer/map-marker.png'
    })

    window.google.maps.event.addListener(location.marker, 'click', () => {
      this.handleClick(location)
      map.setCenter(markerLatLng)
      if (map.getZoom() < 16) {
        map.setZoom(16)
      }
    })

    this.state.markers.push(location.marker)

    // this.oms.addMarker(location.marker)
  }

  render () {
    if (this.props.selectedLocation) {
      let found = this.state.results.find(location => location.Id === this.props.selectedLocation.Id)
      if (found) { map.setCenter({ lat: found.marker.position.lat(), lng: found.marker.position.lng() }) }
      if (map.getZoom() < 16) {
        map.setZoom(16)
      }
    }
    return null
  }
}
