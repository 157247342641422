import { race, put, take, select, delay } from 'redux-saga/effects'
import URLS from '../../shared/constants/urls'
import { fetchData } from '../../shared/services/fetchData'
import { getAutoCompleteDoctors, sortOrder, hasAvailableAppointments } from '../selectors/doctor-search-filter-selectors'
import doctorTransform from '../../shared/data-mappers/doctor-details-transformer'
import { buildProfilePageUrl } from '../../shared/utility-functions'
import { addSearchEvent } from '../../shared/utility-functions/googleAnalytics'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { getSchedulingType } from '../../shared/selectors/shared-scheduling-selectors'
import { FAD_PROVIDERS_ENDPOINT, CIN_PROVIDERS_ENDPOINT } from '../../shared/constants/api-helpers.js'

export default function * proxyProviderLoaderSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_FAD_PROVIDERS')
    let flow = yield select(getSchedulingType)
    if (flow === 'mam') {
      return
    }
    let params = action.params ? Object.assign({}, action.params) : { q: '', locq: '', lat: null, lng: null, dist: null }
    let query = params.q ? params.q : ''
    if (flow === 'sad') params.scheduleOnline = 'true'
    let address = params.locq
    let latitude = params.lat
    let longitude = params.lng
    let within = params.dist
    if (flow === 'cin') {
      params.endPoint = CIN_PROVIDERS_ENDPOINT
    } else {
      params.endPoint = FAD_PROVIDERS_ENDPOINT
    }
  
    yield put({ type: 'SEARCH_PROVIDERS' })
    if (!action.noLoad) {
      yield put({ type: 'CLEAR_PROVIDERS' })
    }
    yield put({ type: 'RESET_AFFILIATED_DOCTORS' })

    let doctors = yield select(getAutoCompleteDoctors)
    if (query.length) {
      if (!action.noLoad) yield put({ type: 'SET_SEARCH_QUERY', value: query })
      params.q = query
    }
    if (address && !action.noLoad) {
      if (action.bounds) {
        yield put({ type: 'SET_AUTO_LOCATION_QUERY', value: address })
      }
      else {
        yield put({ type: 'SET_LOCATION_QUERY', value: address })
      }
    }
    let found = false
    if (doctors && query) {
      found = doctors.filter(doctor => {
        return doctor.name === query
      })[0]
    }

    let fadDefaultSearch = false
    if (flow === 'fad' && (!address || address === '') && !latitude && !longitude) {
      fadDefaultSearch = true
    }

    yield put({ type: 'SET_PROVIDERS_CALL_FAILED', value: false })
    if (action.isBlock) {
      addSearchEvent(params) // fires google analytics event for search
    }

    const { posts } = yield race({
      posts: fetchData(params),
      timeout: delay(waitTime)
    })

    if (posts && posts.success) {
      let cleanProviders = cleanDoctors(posts.results)
      let inOhProviders = fadDefaultSearch ? getInOHProviders(cleanProviders) : cleanProviders
      let sortedProviders = sortProviders(inOhProviders)
      let inBoundsProviders = []
      if (action.bounds && action.bounds.getNorthEast()) {
        for ( let i = 0; i<sortedProviders.length; i++) {
          let prov = sortedProviders[i]
          // clear distance data for auto search
          prov.distance = null
          if (prov.address) prov.address.d = null
          if (prov.addresses && prov.addresses.length > 0) prov.addresses.forEach((adr) => { if (adr.d || adr.d === 0) adr.d = null })
          if (prov.allAddresses && prov.allAddresses.length > 0) prov.allAddresses.forEach((adr) => { if (adr.d || adr.d === 0) adr.d = null })
          if (prov.locations && prov.locations.length > 0) prov.locations.forEach((loc) => { if (loc.distance || loc.distance === 0) loc.distance = null })
          let pushed = false
          if (prov.address && prov.address.lat && prov.address.lng) {
            let latlng = {
              lat: parseFloat(prov.address.lat),
              lng: parseFloat(prov.address.lng)
            }
            if (action.bounds.contains(latlng)) {
              inBoundsProviders.push(prov)
              pushed = true
            }
          }
          if (!pushed && prov.addresses) {
            for (let j = 0; j < prov.addresses.length; j++) {
              let adr = prov.addresses[j]
              let latlng = {
                lat: parseFloat(adr.lat),
                lng: parseFloat(adr.lng)
              }
              if (!pushed && action.bounds.contains(latlng)) {
                inBoundsProviders.push(prov)
                pushed = true
              }
            }
          }
        }
        yield put({ type: 'SET_FAD_MAP_SEARCH_BOUNDS', value: true })
        yield put({ type: 'SET_FAD_MAP_SEARCH_BOUNDS_OBJ', value: action.bounds })
      }
      else {
        yield put({ type: 'CLEAR_FAD_MAP_SEARCH_BOUNDS' })
        yield put({ type: 'SET_FAD_MAP_SEARCH_BOUNDS_OBJ', value: null })
      }

      if (flow !== 'cin' && sortedProviders && sortedProviders.length === 1 && found) { // If user selected a single provider from autocomplete, immediately redirect to that provider's profile
        if (action.noLoad) yield put({ type: 'SET_SEARCH_QUERY', value: query })
        if (address && action.noLoad) {
          if (action.bounds) {
            yield put({ type: 'SET_AUTO_LOCATION_QUERY', value: address })
          }
          else {
            yield put({ type: 'SET_LOCATION_QUERY', value: address })
          }
        }
        window.location.href = buildProfilePageUrl(`http://${window.location.hostname}${URLS.findADoctorUrl}${URLS.profileUrl}/`, sortedProviders[0].title, sortedProviders[0].npi)
        continue
      } else {
        if (action.bounds) {
          yield put({ type: 'SET_PROVIDERS', value: inBoundsProviders })
        } else {
          
          yield put({ type: 'SET_PROVIDERS', value: sortedProviders })
        }
        if (action.noLoad) yield put({ type: 'SET_SEARCH_QUERY', value: query })
        if (address && action.noLoad) {
          if (action.bounds) {
            yield put({ type: 'SET_AUTO_LOCATION_QUERY', value: address })
          }
          else {
            yield put({ type: 'SET_LOCATION_QUERY', value: address })
          }
        }
        let currentSort = yield select(sortOrder)
        // schedStartDates is returning null for all providers, which is making this fire every time. Disabling for now, need to look into why data is null. 
        // if (currentSort === 'First Available') {
        //   let hasAppointments = yield select(hasAvailableAppointments)
        //   if (!hasAppointments) {
        //     yield put({ type: 'RESET_SCHEDULE_A_DOCTOR_SORT_ORDER' })
        //     yield put({ type: 'SET_SELECTED_AVAILABILITY', value: [] })
        //   }
        // }
      }
      if (address && latitude && longitude && within) {
        if (action.bounds) {
          yield put({ type: 'SET_AUTO_SELECTED_SEARCH_LOCATION', value: { locq: address, lat: latitude, lng: longitude, dist: within } })
        }
        else {
          yield put({ type: 'SET_SELECTED_SEARCH_LOCATION', value: { address, latitude, longitude, within } })
        }
      }
      else {
        yield put({ type: 'SET_SELECTED_SEARCH_LOCATION', value: { within: 25 } })
      }
    } else {
      if (action.noLoad) {
        yield put({ type: 'SET_SEARCH_QUERY', value: query })
        if (action.bounds) {
          yield put({ type: 'SET_AUTO_LOCATION_QUERY', value: address })
        }
        else {
          yield put({ type: 'SET_LOCATION_QUERY', value: address })
        }
        yield put({ type: 'CLEAR_PROVIDERS' })
        yield put({ type: 'RESET_AFFILIATED_DOCTORS' })
      } 
      if (action.bounds) {
        yield put({ type: 'SET_FAD_MAP_SEARCH_BOUNDS', value: true })
        yield put({ type: 'SET_FAD_MAP_SEARCH_BOUNDS_OBJ', value: action.bounds })
      }
      else {
        yield put({ type: 'CLEAR_FAD_MAP_SEARCH_BOUNDS' })
        yield put({ type: 'SET_FAD_MAP_SEARCH_BOUNDS_OBJ', value: null })
      }
      yield put({ type: 'SET_PROVIDERS_CALL_FAILED', value: true })
    }
  }
}

function cleanDoctors (dirtyDoctors) {
  return dirtyDoctors.map((dirtyDoctor) => doctorTransform(dirtyDoctor))
}

const sortProviders = (providers) => (
  providers.sort((a, b) => {
    let aLastName = a.lastName ? a.lastName.toUpperCase() : ''
    let bLastName = b.lastName ? b.lastName.toUpperCase() : ''

    return aLastName > bLastName ? 1 : -1
  })
)

const getInOHProviders = (providers) => {
  
//provider.address.s
  let inOH = []
  for ( let i = 0; i < providers.length; i++ ) {
    let prov = providers[i]
    let allAdrInOH = true
    if (prov.address && prov.address.s && prov.address.s !== 'OH') {
      allAdrInOH = false
    }
    if (allAdrInOH && prov.addresses) {
      for (let j = 0; j < prov.addresses.length; j++) {
        let adr = prov.addresses[j]
        if (allAdrInOH && adr.s && adr.s !== 'OH') {
          allAdrInOH = false
        }
      }
    }
    if (allAdrInOH) inOH.push(prov)
  }
  return inOH
}