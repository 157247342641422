import './OBGYNQuestions.scss'
import OBGYNQuestionaireConnector from "../../Blocks/QuestionnaireModal/OBGYNQuestionaire/OBGYNQuestionaireConnector.jsx";


const OBGYNQuestions  = (props) =>{
        return(
            <div className="obgyn-questions-container">
                <div className="obgyn-questions-box">
                    {props.props.showTimeSlots || !props.props.doctor.hasScheduleOnline ? props.timesDrawer() :
                    <OBGYNQuestionaireConnector 
                        componentType={'obflow'}
                        phone={props.props.doctor.phone}
                        doctor={props.props.doctor}
                        showTimeSlots= {props.state.showTimeSlots}
                        timesDrawer = {props.timesDrawer}
                    />
                    }   
                </div>
            </div>
        )
    
}

export default OBGYNQuestions;