import React, { useState, useCallback } from "react";
import SVGInline from 'react-svg-inline'
import "../SuperResultsCard.scss";
import "./SuperResultsMamCard.scss";
import { cleanNumber } from "../../../../../shared/utility-functions/index.js";
import { clickToCallEvent } from "../../../../../shared/utility-functions/googleTagManager.js";
import { SuperResultsMamTimes } from "./SuperResultsMamTimes/SuperResultsMamTimes.jsx";
import calendar from '../../../../../shared/assets/calendar-blue.svg'
import SuperResultsMamTimesConnector from "./SuperResultsMamTimes/SuperResultsMamTimesConnector.jsx";
import isDesktop from "../../../../../shared/utility-functions/isDesktop";
import locationIcon from "../../../../../shared/assets/map-marker.svg";

export const SuperResultsMamCard = (props) => {
  const { location, flow, onMouseEnter, onMouseLeave = () => { return } } = props;
  let showMiles = window.localStorage.getItem('showMiles')

  const clickToCall = () => {
    const dataLayerObject = {
      flow: flow,
      PhoneNumber: location.Phone,
      Name: location.Name,
      ID: location.Id,
      Specialty: "Mammography",
      Address: location.Address ? `${location.Address}, ${location.City} ${location.State} ${location.ZipCode}` : undefined,
    };

    clickToCallEvent(dataLayerObject);
  };
  return (
    <div className='super-results-card--container ' 
    onMouseEnter={onMouseEnter ? () => onMouseEnter([String(location.Id)]) : null} 
    onMouseLeave={onMouseLeave ? () => onMouseLeave([String(location.Id)]) : null}> 
      <div className='super-results-profile--container mam'>
        <div className='profile-image--container'>
          <div className='profile-image--url' style='cursor: unset;'>
            <img
              alt={location.Name}
              src={location.LandscapePhotoURL || "../../../../../shared/assets/no-location.svg"}
              aria-label={location.Name}
              width='120'
            />
          </div>
        </div>
        <div className='profile-details--container' >
          <div className='title-profile-url' style='cursor: unset;'>
            <div className='title'>{location.Name}</div>
          </div>
          <a
            href={`https://maps.apple.com/?q=${location.Address}+${location.City}+${location.State}`}
            target='_blank'
            className='profile-address'>
            {location.Distance !== null && showMiles === 'true'? location.Distance + ' mi - ' : ''}{location.Address + ', ' + location.City}
          </a>
          <div className='phone-number '>
            <a
              href={`tel:${cleanNumber(location.Phone)}`}
              onClick={() => clickToCall()}>
              <span>{location.Phone}</span>
            </a>
          </div>

          {(location.OpenSat || location.OpenSun) && (
            <span className='weekend-availability'>
              <SVGInline svg={calendar} /> Weekend Availability
            </span>
          )}
        </div>
      </div>
      {!isDesktop(window.innerWidth) && (location.Address) &&
            <div className='super-results-address--container outer'>
              <SVGInline className='location-icon' svg={locationIcon} />
              <div className='super-results-address--container inner'>
              {
                <a href={`https://maps.apple.com/?q=${location.Address}+${location.City}+${location.State}`}
                target='_blank'
                className='profile-address-mobile'>
                {location.Distance !== null  && showMiles === 'true' ? location.Distance + ' mi - ' : ''}{location.Address + ', ' + location.City}
                </a> 
              }
              </div>
            </div>
          }
      <SuperResultsMamTimesConnector
        location={location}
        flow={flow}
      />
    </div>
  );
};
